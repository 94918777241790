import React from 'react';

const NotFoundPage = () => {
    return(
        <div className="container">
            <h1>Tadg O'Hare</h1>
            <div className="tab-content">
                <h2>404 - Content Not Found</h2>
                <p>
                    The page you are looking for does not exist, click
                    <a href="/"> here</a> to return to the home page.
                </p>
                <iframe title="monkey_gif" src="https://giphy.com/embed/KpACNEh8jXK2Q" width="480" height="270" className="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/computer-monkey-KpACNEh8jXK2Q">via GIPHY</a></p>
            </div>
        </div>
    );
};

export default NotFoundPage;
