import React, { useState, Children, cloneElement, useRef } from "react";
import { memo } from "react";
import emailjs from '@emailjs/browser';
// import { Document } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const HomePage = () => {
    return(
        <>
        <h2>Home</h2>
        <p>
            Hey I'm Tadg O'Hare (prounounced like tiger without the 'er'), and I am a Computer Science student at Carleton University in Ottawa, Ontario, Canada. I grew up in Hamilton, a medium sized city about 40 minutes west of Toronto (with no traffic), and have been living in Ottawa since 2021 and really love the city, especially all of the outdoor activities this city has to offer both in the urban core and Gatineau Park. English is my mother tongue but I am also proficient in French and always am trying new ways to improve my French whether it be conversations with coworkers and friends or consuming French media, and doing shopping in Gatineau.
        </p>
        <img src="/meinsnow.jpg" alt="Me in the snow" width="50%" height="50%" />
        <p>
            I have always been very computer oriented throughout my entire life and found out that I wanted to pursue computer science professionally since I learned to code in my grade 11 computer science course where I learned Java and found it as a way of expressing myself creatively on the computer, which I was never able to do before. Here is a list of some, but not all of the languages and frameworks that I have worked with either in the classroom, on my own time, at work, or in a semi-professional group setting like a hackathon or club:
        </p>
        <ul>
            <li><a href="https://react.dev/">React</a> (the framework used to build this website)</li>
            <li>C/C++</li>
            <li>Python</li>
            <li>Java</li>
            <li>QT</li>
            <li>OpenAI</li>
            <li>Haskell</li>
            <li><a href="https://flipperzero.one/">FlipperZero</a></li>
            <li>Database management systems including Mongo and SQL (and even working with Excel sheets that should be a DB)</li>
            <li>DAX & VBS within Excel and PowerBI</li>
        </ul>
        <p>
            While I have experience in various programming languages, I also have experience in other tools which are far less coding heavy, but industry standards that are used by the vast majority of office employees including but not limited to:
        </p>
        <ul>
            <li>Excel</li>
            <li>PowerBI</li>
            <li>SharePoint site owner in a corporate repository</li>
            <li>Power Automate</li>
            <li>The rest of MS office suite (Word, Teams, PowerPoint, etc.)</li>
        </ul>
        <p>
            Outside of school and coding, cycling, swimming, lifting weights, hiking and skiing are my favourite activities.
        </p>
        <p>
            While I study computer science and love to code, I like to think of myself as a jack of all trades, and I am very proficient in Microsoft Office, especially Excel and PowerBI and love looking at data and making it available in a readable and aesthetically pleasing way. Both of my internships have had a primary focus with interacting with others who have less technical knowledge and being able to communicate complex ideas in a simple way. Currently I work at Health Canada and do reporting on HR data for various programs as the designated "tech guy" for the team, giving me the opportunity to solve problems for the team without someone higher up needing to be involved.
        </p>
        <p>
            Working with teams that do other things than coding has shown me valuable skills that I would have never learned in a classroom, such as communication with professionals who's area of expertise is non-technical, as well as government standards for information management in the age of digital transformation and the constant threat of cyber attacks. My biggest takeaway in this line of work that is so cruical to coding that I believe everyone should practice is taking the time to read documentation (instead of relying on AI to answer your questions) as well as apply it to your projects for others (and yourself in the future) to understand.
        </p>
        </>
    );
};

const ResumePage = () => {
    const pdfUrl = "https://raw.githack.com/tadgohare/ResumeLatex/master/main.pdf";
    return(
        <>
        <h2>Resume</h2>
        <iframe className="resume-frame" src={pdfUrl} width="100%" height="600px" title="Resume PDF"></iframe>
        {/* <Document file="/Tadg_Resume___2024.pdf" /> */}
        </>
    );
};
const ContactPage = () => {
    //form handler functions
    const form = useRef();
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        message: ''
    });
    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formState);
        emailjs.sendForm('service_a5kog9e', 'template_1h0mk0f', form.current, {publicKey:'Txb6daWDLCuhfBx4B',})
        .then(
            () => {
                console.log("Message sent successfully!");
                setFormState({
                    name: '',
                    email: '',
                    message: ''
                });
                alert("Message successfully sent!");

            },
            (error) => {
                console.log("Message failed to send", error);
                alert("Message failed to send, make sure you typed in the correct information and try again.");
            },
        );
    };
    //HTML component
    return(
        <>
        <h2>Reach out to me</h2>
        <p>I can be contacted through any of the methods bellow, also feel free to reach out to check out my socials or one of the various projects I have done on my GitHub.</p>
        <ul>
            <li><a href="mailto:contact@tadg.ca">conatact@tadg.ca</a></li>
            <li><a href="tel:+12897002048">(289) 700-2048</a></li>
            <li><a href="https://github.com/tadgohare">GitHub</a></li>
            <li><a href="https://linkedin.com/in/tadg-ohare">Linkedin</a></li>
        </ul>
        <div className="contactForm">
            <h2>Send me a message</h2>
            <form ref={form} onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    name="name" 
                    value={formState.name} 
                    onChange={handleChange} 
                    placeholder="Name"
                />
                <br />
                <input 
                    type="email" 
                    name="email" 
                    value={formState.email} 
                    onChange={handleChange} 
                    placeholder="Email"
                />
                <br />
                <textarea 
                    name="message" 
                    value={formState.message} 
                    onChange={handleChange} 
                    placeholder="Message"
                />
                <button type="submit">Send</button>
            </form>
        </div>
        {/* <iframe 
            title="Github Contributions"
            src="https://ghchart.rshah.org/409ba5/tadgohare" 
            style={{border: "0",overflow:"hidden"}} 
            width="600" 
            height="170" 
            allowtransparency="true">
        </iframe> */}
        </>
    );
};

const pages = [<HomePage />, <ResumePage />, <ContactPage />];


const Tab = ({ title, setTab, index, activeTab}) => (
    <button 
        className={`buttonTab ${activeTab === index ? 'activeTab' : ''}`}
        onClick={() => setTab(index)}
    >
        {title}
    </button>
);


const Tabs = ({ children }) => {
    const [currentTab, setTab] = useState(0);
    return (
        <div>
            <div className="tabs">
                {Children.map(children, (child, index) => {
                    return cloneElement(child, { currentTab, setTab, index, activeTab: currentTab })
                })}
            </div>
            <div className="tab-content">
                {pages[currentTab]}
            </div>
        </div>
    );
};


const Page = () => {
    return(
        <div className="container">
        <h1>Tadg O'Hare</h1>
        <Tabs>
            <Tab title="Home"></Tab>
            <Tab title="Resume"></Tab>
            <Tab title="Contact"></Tab>
        </Tabs>
        </div>
    );
    };

export default memo(Page);