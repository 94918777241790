import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page from './Page';
import NotFoundPage from './404';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<Page />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </Router>,
    );

// const container = document.getElementById('root');
// const root = ReactDOM.createRoot(container);
// root.render(<Page />);
